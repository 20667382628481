import React, { Suspense ,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingSkeleton from '../../Shared/Components/SkeletonLoader/LandingSkeleton';
import { LoadingProvider } from '../../Shared/cache/context/LoadingProvider';
import { ga4Initialize, sendGA4PageView } from '../../Shared/helper/google-analytics';
const HomePageComponent = React.lazy(() => import('../pages/HomePageComponent'));
const StaticPageComponent = React.lazy(() => import('../pages/staticPage'));
const PageNotFondComponent = React.lazy(() => import('../pages/pageNotFound'));
const LandingPageLayout = React.lazy(() => import("../layouts/LandingPageLayout"));



const AppRoutes = () => {

  useEffect(() => {
    sendGA4PageView();
    ga4Initialize();
  }, [window.location.pathname]);


  return (
    <Router>
      <LoadingProvider>
        <Suspense fallback={window.location.pathname === "/" ? <LandingSkeleton /> : <div></div>}>
          <LandingPageLayout>
            <Routes>
              <Route path="/" element={<HomePageComponent />} />
              <Route path="/about" element={<StaticPageComponent />} />
              <Route path="/page-not-found" element={<PageNotFondComponent />} />
              <Route path="*" element={<StaticPageComponent />} />
            </Routes>
          </LandingPageLayout>
        </Suspense>
      </LoadingProvider>
    </Router>
  )
}

export default AppRoutes;