import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap3/dist/css/bootstrap.min.css'
import 'bootstrap3/dist/js/bootstrap.min.js'
import './index.scss';
import packageJson from "../package.json";
import { eventTrackerInitialize } from './Shared/service/eventService';

// import Footer from './Shared/Components/Footer/Footer';
// import Header  from './Shared/Components/Header/Header';

import AppRoutes from './app/routes'
const domNode = document.getElementById('root');
const root = createRoot(domNode);
if(process.env.REACT_APP_ENABLE_KPI){
    eventTrackerInitialize();
}
console.log(`%cAirtel - Portal Team(v${packageJson.version}) ⓒ ${(new Date).getFullYear()}`, "color: #e40000; font-size: 18px; font-weight: bold")
root.render(<AppRoutes />);
